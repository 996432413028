<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 p-0 card_item">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <div class="row mb-5">
                  <div class="col-12 text-center">
                    <Button color="dark" size="small" class="white-text" btnText="Back to All Groups" icon2="arrow" @buttonClicked="$emit('close')">
                      <template v-slot:iconStart>
                        <IconArrowBack size="size16" />
                      </template>
                    </Button>
                  </div>
                </div>
                <!--GROUP NAME-->
                <div class="row mx-0 mb-4 justify-content-center align-items-center" >
                  <div class="col-11 card_title text-center p-0">
                    GROUP {{item.donorGroupName}}
                  </div>
                  <div class="col-12 text-center p-0 my-4">
                    <Button color="green" size="small" btnText="Edit Group Name" icon="arrow" @buttonClicked="$emit('editName')">
                      <IconEdit size="size16" />
                    </Button>
                  </div>
                  <div class="col-4 text-center border_bottom">
                  </div>
                </div>
                <!--END GROUP NAME-->
                <!--GROUP FOUNDER-->
                <div class="row mx-0 mb-4 justify-content-center align-items-center" >
                  <div class="col-auto text-center">
                    <div class="profile_img">
                      <IconUser size="sizefull" />
                    </div>
                  </div>
                  <div class="col-12 text-center p-0 mb-4">
                    <div class="bold green-text font19 my-1">
                      {{item.groupFounder.firstName}} {{item.groupFounder.lastName}}
                    </div>
                    <div class="medium green-text font10 letter_spacing">
                      GROUP FOUNDER
                    </div>
                  </div>
                  <div class="col-12 p-0 text-center ">
                    <div class="row mx-0 justify-content-center align-items-center" >
                      <div class="col-4 text-center border_bottom">
                      </div>
                    </div>
                  </div>
                </div>
                <!--END GROUP FOUNDER-->
                <!--GROUP MEMBERS-->
                <div class="row m-0 justify-content-center" >
                  <div class="col-12 px-0 my-3 card_item_inner">
                    <div class="row mx-0 h-100 justify-content-center">
                      <div class="col-12 text-center p-0 my-3 bold green-text font19 my-1">
                        Group Members
                      </div>
                      <div class="col-12 text-center p-0 mb-4 bold green-text font19 my-1">
                        <Button color="green" size="small" btnText="Invite New Donor to Group" icon="arrow" @buttonClicked="$emit('addNew')">
                          <IconPlus size="size16" />
                        </Button>
                      </div>
                      <div class="col-12 p-0">
                        <!--GROUP MEMBERS STATUS-->
                        <div class="row mx-0 my-3 justify-content-center align-items-center" v-if="groupMembersStatus !== 'ready'">
                          <div class="col-auto ps-0 text-capitalize">
                            {{groupMembersStatus}} Group Members
                          </div>
                          <div class="col-auto p-0">
                            <div class="spinner-border spinner-border-sm green-text" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </div>
                        <!--END GROUP MEMBERS STATUS-->
                        <div class="row m-0 justify-content-center">
                          <div class="col-12 p-0" v-for="(member, key) in groupMembers" :key="key">
                            <div class="row m-0 align-items-center borders py-2">
                              <!--IMAGE-->
                              <div class="col-12 text-center">
                                <Image :imageName="member.imageUrl" width="40" theClass="item_img" v-if="member.imageUrl" />
                                <!-- <img :src="url + '/static/uploads/images/' + member.imageUrl"  class="item_img" v-if="member.imageUrl"> -->
                                <img src="../assets/images/Bismillah-Khutbah-1.png"  class="item_img" v-else>
                              </div>
                              <!--END IMAGE-->
                              <div class="col-12 p-0 text-center mt-2">
                                <div class="row m-0">
                                  <div class="col-12 p-0 bold item">
                                    {{member.firstName}} {{member.lastName}}
                                  </div>
                                  <div class="col-12 p-0 font12">
                                    {{ member.email }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 my-2 item">
                                <div class="row m-0">
                                  <div class="col-6 px-1">
                                    <Button color="red" size="small" btnText="Donate" icon="arrow" width="100" @buttonClicked="makeDonation">
                                      <IconArrowForward size="size16" />
                                    </Button>
                                  </div>
                                  <div class="col-6 px-1">
                                    <Button color="darkgrey" icon="arrow" size="small"  width="100" btnText="Remove" @buttonClicked="removeMember(member)">
                                      <ios-close-icon class="inline-icon" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 align-self-end">
            <div class="row m-0 background-green py-3 align-items-center justify-content-center">
              <div class="col-11 mb-3">
                <Button color="green_light" size="" width="100" btnText="Invite New Donor to Group" icon="arrow" @buttonClicked="addNew">
                  <IconPlus />
                </Button>
              </div>
              <div class="col-11">
                <Button color="red" size="" width="100" btnText="Delete Group" icon="arrow" @buttonClicked="deleteGroup">
                  <IconPlus />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconArrowBack: defineAsyncComponent(() => import('../components/icons/IconArrowBack.vue')),
    IconUser: defineAsyncComponent(() => import('../components/icons/IconUser.vue')),
    IconPlus: defineAsyncComponent(() => import('../components/icons/IconPlus.vue')),
    IconEdit: defineAsyncComponent(() => import('../components/icons/IconEdit.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Donation Popup',
  props: ['item', 'theKey', 'totalItems'],
  async mounted () {
    await this.fetchGroupMembers(this.item.donorGroupID)
  },
  computed: {
    ...mapGetters(['groupMembers', 'groupMembersStatus'])
  },
  methods: {
    ...mapActions(['fetchGroupMembers']),
    addNew () {
      this.$emit('addNew')
    },
    editName () {
      this.$emit('editName')
    },
    deleteGroup () {
      this.$emit('deleteGroup')
    },
    removeMember (val) {
      this.$emit('removeMember', val)
    }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
</style>
